import React, { useState, useEffect } from "react";

import "./App.css"; // Importa el archivo CSS



function App() {

const [horaActualizacion, setHoraActualizacion] = useState(new Date());

const [data, setData] = useState([]);

// Estado para almacenar la columna actual y la dirección del ordenamiento

const [orden, setOrden] = useState({ columna: null, direccion: 'asc' }); 



useEffect(() => {
    const intervalo = setInterval(() => {
      setHoraActualizacion(new Date());
    }, 600000); // Actualiza cada 10 minutos
  
    return () => clearInterval(intervalo);
  }, []);



useEffect(() => {

const obtenerDatos = async () => {

try {

const response = await fetch("https://internoback.binomia.mx/datos");//3001

const jsonData = await response.json();

setData(jsonData);

} catch (error) {

console.error("Error al obtener los datos:", error);

}

};



obtenerDatos();

setInterval(obtenerDatos, 60000); // Actualizar cada minuto

}, []);



const ordenarTabla = (columna) => {

let direccion = 'asc';



if (orden.columna === columna) {

direccion = orden.direccion === 'asc' ? 'desc' : 'asc';

}



const sortedData = [...data].sort((a, b) => {

const valorA = isNaN(parseFloat(a[columna])) ? a[columna].toString().toLowerCase() : parseFloat(a[columna]);

const valorB = isNaN(parseFloat(b[columna])) ? b[columna].toString().toLowerCase() : parseFloat(b[columna]);



if (typeof valorA === 'number') {

if (direccion === 'asc') {

return valorA - valorB;

} else {

return valorB - valorA;

}

} else {

if (direccion === 'asc') {

return valorA.localeCompare(valorB);

} else {

return valorB.localeCompare(valorA);

}

}

});



setData(sortedData);

setOrden({ columna: columna, direccion: direccion });

};



// Obtener la fecha de hoy en formato "dd/mm/aaaa"

const fechaActual = new Date().toLocaleDateString("es-ES");
const formatoHora = horaActualizacion.toLocaleTimeString('es-ES', { hour12: false });


return (

<div>

<h1 style={{ color: "#F26122", margin:"20px" }}>Encuestas Altán {fechaActual}</h1>

 {/* Mostrar la hora de actualización con la fuente y el color */}
 {horaActualizacion && (
        <p
          style={{
            fontFamily: "Amstagram, sans-serif", // Agrega la fuente
            color: "#003E52", // Cambia el color del texto
            textAlign: "left",
            margin: "20px",
            fontSize: "24px", // Cambia el tamaño de la letra a 16px
          }}
        >
          Información actualizada a las {formatoHora}
        </p>
      )}

<div style={{ overflowX: "auto", margin: "20px" }}> 

<table className="tabla-responsiva">

<thead>

<tr style={{ backgroundColor: "#003E52", color: "#FFFFFF" }}>

<th data-label="Base" onClick={() => ordenarTabla("Base")} style={{ width: '14.285714%' }}> 

Base

</th>

<th

data-label="Registros Trabajados"

onClick={() => ordenarTabla("RegistrosTrabajados")}

style={{ width: '7.142857%' }}

>

Registros Trabajados

</th>

<th data-label="Contactos" onClick={() => ordenarTabla("Contactos")} style={{ width: '7.142857%' }}>

Contactos

</th>

<th data-label="% Contactados" onClick={() => ordenarTabla("% Contactados")} style={{ width: '7.142857%' }}>

% Contactados

</th>

<th data-label="No contactos" onClick={() => ordenarTabla("No contactos")} style={{ width: '7.142857%' }}>

No contactos

</th>

<th data-label="% No Contactados" onClick={() => ordenarTabla("% No Contactados")} style={{ width: '7.142857%' }}>

% No Contactados

</th>

<th data-label="Contacto Efectivo" onClick={() => ordenarTabla("Contacto Efectivo")} style={{ width: '7.142857%' }}>

Contacto Efectivo

</th>

<th data-label="% Contacto Efectivo" onClick={() => ordenarTabla("% Contacto Efectivo")} style={{ width: '7.142857%' }}>

% Contacto Efectivo

</th>

<th data-label="Contacto no Efectivo" onClick={() => ordenarTabla("Contacto no Efectivo")} style={{ width: '7.142857%' }}>

Contacto no Efectivo

</th>

<th data-label="% Contacto no Efectivo" onClick={() => ordenarTabla("% Contacto no Efectivo")} style={{ width: '7.142857%' }}>

% Contacto no Efectivo

</th>

<th data-label="Encuestas" onClick={() => ordenarTabla("Encuestas")} style={{ width: '7.142857%' }}>

Encuestas

</th>

<th data-label="% Encuestas" onClick={() => ordenarTabla("% Encuestas")} style={{ width: '7.142857%' }}>

% Encuestas

</th>

<th data-label="Marcaciones" onClick={() => ordenarTabla("Marcaciones")} style={{ width: '7.142857%' }}>

Marcaciones

</th>

</tr>

</thead>

<tbody>

{data.map((row, index) => (

<tr

key={index}

style={{

backgroundColor: index % 2 === 0 ? "#6BCDB2" : "#FFFFFF",

}}

>

<td data-label="Base">{row.Base}</td>

<td data-label="Registros Trabajados">{row.RegistrosTrabajados}</td>

<td data-label="Contactos">{row.Contactos}</td>

<td data-label="% Contactados">{(row["% Contactados"] * 100).toFixed(2)}%</td>

<td data-label="No contactos">{row["No contactos"]}</td>

<td data-label="% No Contactados">{(row["% No Contactados"] * 100).toFixed(2)}%</td>

<td data-label="Contacto Efectivo">{row["Contacto Efectivo"]}</td>

<td data-label="% Contacto Efectivo">{(row["% Contacto Efectivo"] * 100).toFixed(2)}%</td>

<td data-label="Contacto no Efectivo">{row["Contacto no Efectivo"]}</td>

<td data-label="% Contacto no Efectivo">{(row["% Contacto no Efectivo"] * 100).toFixed(2)}%</td>

<td data-label="Encuestas">{row.Encuestas}</td>

<td data-label="% Encuestas">{(row["% Encuestas"] * 100).toFixed(2)}%</td>

<td data-label="Marcaciones">{row.Marcaciones}</td>

</tr>

))}

</tbody>

</table>

</div> {/* Cierra el div contenedor */}

</div>

);

}



export default App;

